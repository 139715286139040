import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet"
import Blog from "./pages/Blog";
import Contact from "./pages/Contact";
import DevApp from "./pages/DevApp";
import DevDigmrkg from "./pages/DevDigmrkg";
import DevSoft from "./pages/DevSoft";
import DevWeb from "./pages/devWeb/DevWeb";
import Home from './pages/Home'
import Login from "./pages/Login";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Services from "./pages/Services";
import ThankYou from "./pages/ThankYou";
import ThanksGiving from "./pages/promotions/greetings/ThanksGiving";
import Applications from "./pages/Applications";
import Surrey from "./pages/locations/Surrey";
import Vancouver from "./pages/locations/Vancouver";
import Ecommerce from "./pages/devWeb/Ecommerce";
import WebApp from "./pages/devWeb/WebApp";
import BlogDetails from "./pages/BlogDetails";


function App() {
  return (
    <Router>
      <Helmet>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="format-detection" content="telephone=no" />
        <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />
        <link rel="canonical" href="https://odidor.co/" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />

        <title>Web, App development & Digital Marketing Splendid Production</title>
        <meta name="description" content="Web Design Vancouver - Splendid Production is a Vancouver web & app development company offering unique and affordable web and app design, development and eCommerce website design solutions in Vancouver. " />
        <meta name="keywords" content="Web development, app development, web designs and custom website  development, E-commerce web development and solutions, SEO and digital marketing solutions, CMS & Backend development" />
        <meta property="og:title" content="Web development & Design services" />
        <meta property="og:description"
          content="Web Design Vancouver - Splendid Production is a Vancouver web & app development company offering unique and affordable web and app design, development and eCommerce website design solutions in Vancouver." />
        <meta property="og:type" content="Website" />
        <meta property="og:url" content="https://odidor.co/" />
        <meta property="og:image" content="https://odidor.co/assets/img/banner/01.png" />
        <meta property="og:image:width" content="" />
        <meta property="og:image:height" content="" />

        <meta name="twitter:title" content="Web, App development & Digital Marketing Splendid Production" />
        <meta name="twitter:description"
          content="Web Design Vancouver - Splendid Production is a Vancouver web & app development company offering unique and affordable web and app design, development and eCommerce website design solutions in Vancouver." />
        <meta name="twitter:image" content="https://odidor.co/assets/img/banner/01.png" />
        <meta name="twitter:card" content="Website" />

        <meta property="og:site_name" content="Odidor " />
        <meta property="og:title" content="Web, App development & Digital Marketing Splendid Production" />
        <meta property="og:type" content="Website" />
        <meta property="og:description"
          content="Web Design Vancouver - Splendid Production is a Vancouver web & app development company offering unique and affordable web and app design, development and eCommerce website design solutions in Vancouver." />
        <meta property="og:image" content="https://odidor.co/assets/img/banner/01.png" />
        <meta property="og:url" content="https://odidor.co/" />
      </Helmet>
      <Routes>
        {/* Main Pages */}
        <Route path="/*" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/apps" element={<Applications />} />
        <Route path="/services/app-development" element={<DevApp />} />
        {/* Website development */}
        <Route path="/services/website-development" element={<DevWeb />} />
        <Route path="/services/website-development/ecommerce-web-development" element={<Ecommerce />} />
        <Route path="/services/website-development/web-app-development" element={<WebApp />} />

        <Route path="/services/software-development" element={<DevSoft />} />
        <Route path="/services/digital-marketing" element={<DevDigmrkg />} />
        <Route path="/news-and-updates" element={<Blog />} />
        <Route path="/news-and-updates/details/:slug" element={<BlogDetails />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/Thank-You" element={<ThankYou />} />

        {/* Location Pages */}
        <Route path="/location/surrey" element={<Surrey />} />
        <Route path="/location/vancouver" element={<Vancouver />} />

        {/* Greetings pages */}
        {/* <Route path="/happy-thanksgiving" element={<ThanksGiving />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
